import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NewDeclarationModalInputs from "./ModalInputs";
import headerObligations from "../../../../constants/obligations/header-obligations";
import itemObligations from "../../../../constants/obligations/items-obligations";
import Modal from "../../../modals/Modal";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import { FSD_DEFAULT_DATA } from "./constants";

const defaultInputValues = {
	service: "import",
	protocol: "no",
	importType: "no",
	risk: "no",
};

const NewDeclarationModal = (props) => {
	const [inputValues, setInputValues] = useState(defaultInputValues);
	const { createDeclaration } = useContext(DeclarationsContext);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const { isOpen, onClose, onSubmit } = props;
	const { t } = useTranslation();

	const handleInputChange = (input, value) => {
		if (input === "service" && value !== "import") {
			inputValues.importType = "no";
			inputValues.protocol = "no";
		} else if (input === "importType" && value !== "no") {
			inputValues.protocol = "no";
		}
		setInputValues({
			...inputValues,
			[input]: value,
		});
	};

	const handleSubmit = async () => {
		setIsSaveLoading(true);

		let { service, protocol, risk, importType } = inputValues;

		if (service === "import" && protocol !== "no") {
			service = `${protocol}${risk === "yes" ? "AtRisk" : "NotAtRisk"}`;
		}

		let declarationData = {
			service,
			importType,
			functionCode: 9,
			declarationType: service === "export" ? "EX" : "IM",
		};

		// Add default/pre-populated service header obligation elements
		Object.keys(headerObligations.services).forEach((serviceKey) => {
			if (serviceKey === service) {
				Object.keys(headerObligations.services[serviceKey]).forEach((elementKey) => {
					const value = headerObligations.services[serviceKey][elementKey];
					declarationData[elementKey] = value;
				});
			}
		});

		let itemState = { name: "Item 1" };

		// Add default/pre-populated service item obligation elements
		Object.keys(itemObligations.services).forEach((serviceKey) => {
			if (serviceKey === service) {
				Object.keys(itemObligations.services[serviceKey]).forEach((elementKey) => {
					const value = itemObligations.services[serviceKey][elementKey];

					itemState[elementKey] = value;
				});
			}
		});

		declarationData.declarationItems = [itemState];

		if (importType === "finalSupplementary") {
			declarationData = {
				...declarationData,
				...FSD_DEFAULT_DATA,
			};
		}

		try {
			const result = await createDeclaration({
				data: declarationData,
				isFinalSupplementary: importType === "finalSupplementary",
			});

			onSubmit?.(result);
			onClose();
		} catch (e) {
			console.log(e);
		}

		setIsSaveLoading(false);
	};

	useEffect(() => {
		if (isOpen) {
			setInputValues(defaultInputValues);
			setIsSaveLoading(false);
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} label={t("newDeclaration")} style={{ width: 560 }}>
			<NewDeclarationModalInputs
				inputValues={inputValues}
				isLoading={isSaveLoading}
				onSubmitClick={handleSubmit}
				onInputChange={handleInputChange}
				onCancelClick={onClose}
			/>
		</Modal>
	);
};

export default NewDeclarationModal;
