import { PiX } from "react-icons/pi";
import Button from "../../../common/Button";
import { useContext } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";

const AIChatBoxHeader = ({ onClose }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div className={withTheme("chat-box-header")}>
			<div style={{ fontWeight: 700 }}>Tax2Cargo AI</div>

			<div style={{ width: "fit-content", marginLeft: "auto" }}>
				<Button type="ghost" onClick={onClose}>
					<PiX fontSize={14} />
				</Button>
			</div>
		</div>
	);
};

export default AIChatBoxHeader;
