import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { findElementPageNumber, getQueryParam, scrollToElement, updateQueryParam } from "../../../../router/utils";
import importGrouping from "../../../../constants/grouping/import/items-grouping.json";
import exportGrouping from "../../../../constants/grouping/export/items-grouping.json";
import ButtonGroup from "../../../../components/common/ButtonGroup/ButtonGroup";
import { ELEMENT_QUERY_PARAM, PAGE_QUERY_PARAM } from "../../../../constants/GlobalConstants";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import ItemsList from "../../../../components/layouts/declarations/DeclarationItems/ItemsList/ItemsList";
import SectionsMasonry from "../../SectionsMasonry";
import ItemHeader from "./ItemHeader";
import { debounce, isNumber } from "lodash";

const Items = () => {
	const [items, setItems] = useState([]);
	const lastFocusedElement = useRef();
	const { state, findElementObligations } = useContext(DeclarationsContext);
	const [selectedPageIndex, setSelectedPageIndex] = useState(0);
	const [selectedItemIndex, setSelectedItemIndex] = useState(0);

	const pages = useMemo(() => {
		if (state.declaration?.service === "export") {
			return exportGrouping;
		} else {
			return importGrouping;
		}
	}, [selectedPageIndex]);

	const updatePageQueryParam = (index) => {
		updateQueryParam(ELEMENT_QUERY_PARAM, "");
		updateQueryParam(PAGE_QUERY_PARAM, index);
	};

	const findElementAndScroll = () => {
		const element = getQueryParam(ELEMENT_QUERY_PARAM);
		const pageIndex = parseInt(getQueryParam(PAGE_QUERY_PARAM));

		if (element && lastFocusedElement.current !== element) {
			const elementPageIndex = findElementPageNumber("items", element, state.declaration);

			if (isNumber(elementPageIndex) && elementPageIndex !== pageIndex) {
				updateQueryParam(PAGE_QUERY_PARAM, elementPageIndex);
				setTimeout(() => {
					scrollToElement(element);
				}, 100);
			} else {
				scrollToElement(element);
			}
		}

		lastFocusedElement.current = element;
	};

	const getStateFromQuery = useCallback(
		debounce(() => {
			const page = getQueryParam(PAGE_QUERY_PARAM);
			const itemIndex = getQueryParam("item");

			if (itemIndex) {
				setSelectedItemIndex(parseInt(itemIndex));
			}

			setSelectedPageIndex(parseInt(page) || 0);
			findElementAndScroll();
		}, 0),
		[]
	);

	useEffect(() => {
		const eventHandler = (e) => {
			getStateFromQuery();
		};

		window.addEventListener("popstate", eventHandler);

		return function cleanup() {
			window.removeEventListener("popstate", eventHandler);
		};
	}, [state.declaration]);

	useEffect(() => {
		getStateFromQuery();
		findElementAndScroll();
	}, [state.declaration._id]);

	useEffect(() => {
		findElementObligations("items");
	}, [state.declaration]);

	useEffect(() => {
		setItems([...(state.declaration.data.declarationItems || [])]);
	}, [state.declaration._id]);

	return (
		<div style={{ display: "flex" }}>
			<ItemsList items={items} setItems={setItems} selectedItemIndex={selectedItemIndex} />
			<div style={{ width: "calc(100% - 88px)", paddingLeft: 16 }}>
				<div style={{ width: "fit-content", marginLeft: "auto" }}>
					<ButtonGroup
						selectedIndex={selectedPageIndex}
						buttons={pages.map((tab, index) => ({
							label: `Page ${index + 1}`,
							onClick: () => updatePageQueryParam(index),
						}))}
					/>
				</div>
				<ItemHeader selectedItemIndex={selectedItemIndex} />
				<div style={{ marginTop: 12 }}>
					<SectionsMasonry
						sections={pages[selectedPageIndex]?.sections}
						selectedItemIndex={selectedItemIndex}
					/>
				</div>
			</div>
		</div>
	);
};

export default Items;
