import { useContext, useState } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import AIChatBoxHeader from "./components/Header";
import MessageInput from "./components/MessageInput";
import Message from "./components/Message";
import axios from "axios";
import config from "../../../config";
import { getHeaders } from "../../../api/helpers";

// TODO: Handle messages scrolling
// TODO: Add loading ... when waiting for response
const AIChatBox = ({ isOpen, onClose }) => {
	const { withTheme } = useContext(ThemeContext);
	const [messages, setMessages] = useState([]);

	if (!isOpen) {
		return null;
	}

	const handleMessageSend = async (message) => {
		const updatedMessages = [...messages, { role: "user", content: message }];

		setMessages(updatedMessages);

		try {
			const response = await axios.post(
				config.apiUrl + "/llm",
				{ messages: updatedMessages },
				{
					headers: getHeaders(),
				}
			);

			setMessages([...updatedMessages, response.data.response]);
		} catch (error) {
			console.error("Error sending message:", error);
		}
	};

	return (
		<div className={withTheme("chat-box")}>
			<AIChatBoxHeader onClose={onClose} />

			<div className={withTheme("chat-box-messages-container")}>
				{messages.map((message, index) => (
					<Message message={message} key={index} />
				))}
			</div>

			<MessageInput onSend={handleMessageSend} />
		</div>
	);
};

export default AIChatBox;
