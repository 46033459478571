import { useContext, useState } from "react";
import { IoSendOutline } from "react-icons/io5";
import ThemeContext from "../../../../context/theme/ThemeContext";

const MessageInput = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const [isFocused, setIsFocused] = useState(false);
	const [inputValue, setInputValue] = useState("");

	const { containerStyle, onFocus, onBlur, onChange, onKeyDown, onSend, ...rest } = props;

	const handleSend = () => {
		onSend(inputValue);
		setInputValue("");
	};

	return (
		<div
			style={props.containerStyle}
			className={`${withTheme("message-input-container")} ${
				isFocused ? withTheme("message-input-container-focused") : ""
			}`}
		>
			<textarea
				placeholder={props.placeholder || "Enter Message..."}
				className={withTheme("message-input")}
				value={inputValue}
				onFocus={() => {
					onFocus?.();
					setIsFocused(true);
				}}
				onBlur={() => {
					onBlur?.();
					setIsFocused(false);
				}}
				onKeyDown={(e) => {
					if (!e.shiftKey && e.key === "Enter") {
						handleSend(inputValue);
						e.preventDefault();
					}
					onKeyDown?.(e);
				}}
				onChange={(e) => {
					setInputValue(e.target.value);
					onChange?.(e);
				}}
				{...rest}
			/>

			<IoSendOutline
				onClick={() => handleSend(inputValue)}
				style={{ color: "#B0B5BD", fontSize: 16, cursor: "pointer" }}
			/>
		</div>
	);
};

export default MessageInput;
