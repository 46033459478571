const commodityCodeNationalAdditionalCode = [
	{
		key: "VATE",
		value: "VAT Exempt duty rate",
	},
	{
		key: "VATR",
		value: "VAT 5% duty rate",
	},
	{
		key: "VATZ",
		value: "VAT 0% duty rate",
	},
	{ key: "X301", value: "Low Alcohol - not exc 1.2%" },
	{ key: "X311", value: "Beer less than 3.5% abv" },
	{ key: "X312", value: "Cider less than 3.5% abv" },
	{ key: "X313", value: "Wine less than 3.5% abv" },
	{ key: "X314", value: "Other fermented products less than 3.5% abv" },
	{ key: "X315", value: "Spirits less than 3.5% abv" },
	{ key: "X321", value: "Beer at least 3.5% abv but less than 8.5% abv" },
	{
		key: "X322",
		value: "Cider at least 3.5% abv but less than 8.5% abv & sparkling cider at least 3.5% abv not exceeding 5.5% abv",
	},
	{ key: "X323", value: "Wine at least 3.5% abv but less than 8.5% abv" },
	{
		key: "X324",
		value: "Other fermented products at least 3.5% abv but less than 8.5% abv & sparkling cider exceeding 5.5% abv but less than 8.5% abv",
	},
	{ key: "X325", value: "Spirits at least 3.5% abv but less than 8.5% abv" },
	{ key: "X331", value: "Beer at least 8.5% abv but not exceeding 22% abv" },
	{ key: "X333", value: "Wine at least 8.5% abv but not exceeding 22% abv" },
	{ key: "X334", value: "Other fermented products at least 8.5% abv but not exceeding 22% abv" },
	{ key: "X335", value: "Spirits at least 8.5% abv but not exceeding 22% abv" },
	{ key: "X341", value: "Beer exceeding 22% abv" },
	{ key: "X343", value: "Wine exceeding 22% abv" },
	{ key: "X344", value: "Other fermented products exceeding 22% abv" },
	{ key: "X345", value: "Spirits exceeding 22% abv" },
	{ key: "X351", value: "Beer less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X352", value: "Cider less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X353", value: "Wine less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X354", value: "Other fermented products less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X355", value: "Spirits less than 3.5% abv & eligible for Draught Relief" },
	{ key: "X356", value: "Beer at least 3.5% abv but less than 8.5% abv & eligible for Draught Relief" },
	{
		key: "X357",
		value: "Cider at least 3.5% abv but less than 8.5% abv & Sparkling cider at least 3.5% abv but less than not exceeding 5.5% abv & eligible for Draught Relief",
	},
	{ key: "X358", value: "Wine at least 3.5% abv but less than 8.5% abv & eligible for Draught Relief" },
	{
		key: "X359",
		value: "Other fermented products at least 3.5% abv but less than 8.5% abv & Sparkling cider exceeding 5.5% abv but less than 8.5% abv & eligible for Draught Relief",
	},
	{ key: "X360", value: "Spirits at least 3.5% abv but less than 8.5% abv & eligible for Draught Relief" },
	{ key: "X361", value: "Beer less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X362", value: "Cider less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X363", value: "Wine less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X364", value: "Other fermented products less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X365", value: "Spirits less than 3.5% abv & eligible for Small Producer Relief" },
	{ key: "X366", value: "Beer at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief" },
	{
		key: "X367",
		value: "Cider at least 3.5% abv but less than 8.5% abv & Sparkling cider at least 3.5% abv but less than not exceeding 5.5% abv & eligible for Small Producer Relief",
	},
	{ key: "X368", value: "Wine at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief" },
	{
		key: "X369",
		value: "Other fermented products at least 3.5% abv but less than 8.5% abv & Sparkling cider exceeding 5.5% abv but less than 8.5% abv & eligible for Small Producer Relief",
	},
	{ key: "X370", value: "Spirits at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief" },
	{ key: "X371", value: "Beer less than 3.5% abv & eligible for Small Producer Relief and Draught Relief" },
	{ key: "X372", value: "Cider less than 3.5% abv & eligible for Small Producer Relief and Draught Relief" },
	{ key: "X373", value: "Wine less than 3.5% abv & eligible for Small Producer Relief and Draught Relief" },
	{
		key: "X374",
		value: "Other fermented products less than 3.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{ key: "X375", value: "Spirits less than 3.5% abv & eligible for Small Producer Relief and Draught Relief" },
	{
		key: "X376",
		value: "Beer at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{
		key: "X377",
		value: "Cider at least 3.5% abv but less than 8.5% abv & Sparkling cider at least 3.5% abv but less than not exceeding 5.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{
		key: "X378",
		value: "Wine at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{
		key: "X379",
		value: "Other fermented products at least 3.5% abv but less than 8.5% abv & Sparkling cider exceeding 5.5% abv but less than 8.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{
		key: "X380",
		value: "Spirits at least 3.5% abv but less than 8.5% abv & eligible for Small Producer Relief and Draught Relief",
	},
	{ key: "X639", value: "UK tax type 639, Snuff" },
	{
		key: "X511",
		value: "UK tax type 511, Light oil (unmarked) - aviation gasoline (including light oil aviation turbine fuel)",
	},
	{
		key: "X520",
		value: "UK tax type 520, Light oil (unmarked) - other unrebated light oil",
	},
	{
		key: "X521",
		value: "UK tax type 521, Light oil, furnace fuel",
	},
	{
		key: "X522",
		value: "UK tax type 522, Light oil, unleaded fuel",
	},
	{
		key: "X540",
		value: "UK tax type 540, Heavy oil - other (unmarked) heavy oil (other than kerosene) intended for use as heating fuel, or as fuel for an engine; which would otherwise be eligible for a full rebate",
	},
	{
		key: "X541",
		value: "UK tax type 541, Heavy oil (unmarked; including Diesel Engine Road Vehicle (DERV)) or road fuel extender and unmarked kerosene or unmarked gas oil for which no marking waiver has been granted",
	},
	{
		key: "X542",
		value: "UK tax type 542, Heavy oil kerosene to be used as motor fuel off road or in an excepted vehicle",
	},
	{
		key: "X551",
		value: "UK tax type 551, Heavy oil kerosene (marked/unmarked under marking waiver, including heavy oil aviation turbine fuel) to be used other than as motor fuel off-road or in an excepted vehicle",
	},
	{
		key: "X556",
		value: "UK tax type 556, Heavy gas oil (marked/unmarked under marking waiver)",
	},
	{
		key: "X561",
		value: "UK tax type 561, Heavy oil, fuel oil (unmarked)",
	},
	{
		key: "X570",
		value: "UK tax type 570, Heavy oil, other (unmarked)",
	},
	{
		key: "X571",
		value: "UK tax type 571, Biodiesel to be used as motor fuel off road or in an excepted vehicle",
	},
	{
		key: "X572",
		value: "UK tax type 572, Biodiesel blended with kerosene for use as heating fuel",
	},
	{
		key: "X589",
		value: "UK tax type 589, Biodiesel",
	},
	{
		key: "X591",
		value: "UK tax type 591, Road fuel gases - natural gas including biogas",
	},
	{
		key: "X592",
		value: "UK tax type 592, Road fuel gases - other than natural gas, e.g. LPG",
	},
	{
		key: "X595",
		value: "UK tax type 595, Bioethanol",
	},
	{
		key: "X611",
		value: "UK tax type 611, Cigarettes",
	},
	{
		key: "X615",
		value: "UK tax type 615, Cigars",
	},
	{
		key: "X619",
		value: "UK tax type 619, Hand rolling tobacco",
	},
	{
		key: "X623",
		value: "UK tax type 623, Smoking tobacco - other",
	},
	{
		key: "X627",
		value: "UK tax type 627, Chewing tobacco",
	},
	{
		key: "X633",
		value: "UK tax type 633, Tobacco for heating",
	},
	{
		key: "X639",
		value: "UK tax type 639, Snuff",
	},
	{
		key: "X99A",
		value: "Solid fuels i.e. coal and lignite, coke and semi-coke of coal or lignite, and petroleum coke",
	},
	{
		key: "X99B",
		value: "Any petroleum gas, or other gaseous hydrocarbon supplied in a liquid state",
	},
	{
		key: "X99C",
		value: "Gas supplied by a gas utility or any gas supplied in a gaseous state that is of a kind supplied by a gas utility Great Britain",
	},
	{
		key: "X99D",
		value: "Electricity",
	},
];

export default commodityCodeNationalAdditionalCode;
