import { useContext } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";

const Message = ({ message }) => {
	const { withTheme } = useContext(ThemeContext);

	return <div className={withTheme(`chat-box-message chat-box-${message.role}-message`)}>{message.content}</div>;
};

export default Message;
